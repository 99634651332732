<template>
  <div class="sub-menu">
    <div class="nav-bar">
      <div class="menu-links">
        <div
          class="menu-link"
          v-if="getPermissionInterface('Validation salaires')"
        >
          <router-link
            class="menu-item"
            to="/employes/validation-salaire-admin"
          >
            Validation salaires
          </router-link>
        </div>
        <div
          class="menu-link"
          v-if="getPermissionInterface('Validation indépendants')"
        >
          <router-link
            class="menu-item"
            to="/employes/validation-salaire-commerciaux"
          >
            Validation indépendants
          </router-link>
        </div>
        <div
          class="menu-link"
          v-if="getPermissionInterface('Suivi indépendants')"
        >
          <router-link class="menu-item" to="/employes/suivi-independant">
            Suivi des indépendants
          </router-link>
        </div>
        <div
          class="menu-link"
          v-if="getPermissionInterface('Récapitulatif commerciaux par zone')"
        >
          <router-link class="menu-item" to="/employes/recap-commerciaux">
            Récapitulatif commerciaux par zone
          </router-link>
        </div>
      </div>
    </div>
    <div class="body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getUserData', 'getPermissionInterface'])
  }
};
</script>

<style scoped lang="scss"></style>
